import { Axios } from 'axios';

/**
 *
 * @param {Axios} api
 * @returns
*/

export default function makeCustomFieldService(api) {
  return {
    getCustomFields: function (handle, projectKey) {
      return api.get(`/${handle}/projects/${projectKey}/customFields`);
    },

    createCustomField: function (handle, projectKey, data) {
      return api.post(`/${handle}/projects/${projectKey}/customFields`, data);
    },

    updateCustomField: function (handle, projectKey, { uid, ...data }) {
      return api.patch(`/${handle}/projects/${projectKey}/customFields/${uid}`, data);
    },

    deleteCustomField: async function (handle, projectKey, uid) {
      return api.delete(`/${handle}/projects/${projectKey}/customFields/${uid}`);
    },
  }
}
