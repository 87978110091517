<template>
  <v-form
    id="login-form"
    ref="form"
    v-model="isCreateTestCaseFormValid"
    role="createTestCaseForm"
    class="pl-3"
  >
    <div
      class="white fill-height v-align-start font-inter rounded-lg app-height-global pb-3 mt-6"
    >
      <v-row
        align-content="center"
        justify="center"
      >
        <v-col
          cols="12"
          sm="12"
          class="back-btn-container card px-8"
        >
          <div class="back-btn">
            <router-link
              v-if="isExecution"
              :to="{
                name: 'TestRunCaseEdit',
                params: { handle: $route.params.handle, key: $route.params.key, id: $route.query.id },
              }"
            >
              <v-icon>mdi-chevron-left</v-icon> {{ $t("backToTestExecutions") }}
            </router-link>
            <router-link
              v-else
              :to="{
                name: 'Cases',
                params: { handle: $route.params.handle, key: $route.params.key },
              }"
            >
              <v-icon>mdi-chevron-left</v-icon> {{ $t("backToTestCases") }}
            </router-link>
          </div>
        </v-col>
        <v-col
          cols="12"
          sm="12"
          class="case-contents"
        >
          <div
            v-if="!isEditMode"
            class="item-area mb-5"
          >
            <h2>{{ $t("createNewTestCase") }}</h2>
          </div>
          <div 
            v-else-if="isExecution"
            class="item-area mb-5"
          >
            <h2>{{ $t('editExecution') }}</h2>
          </div>
          <div
            v-else
            class="item-area mb-5"
          >
            <h2>{{ $t("editTestCase") }}</h2>
          </div>
          <div
            v-if="!isExecution"
            class="item-area ml-10 mb-1"
          >
            <v-label class="text-left fs-14px text-theme-label font-weight-medium">
              {{ $t("template") }}
            </v-label>
          </div>
          <div
            v-if="!isExecution"
            class="item-area"
          >
            <icon-dots />
            <v-select
              v-model="templateId"
              class="ml-5 mt-0 pt-1 rounded-lg field-theme custom-prepend"
              :items="templates"
              background-color="#F9F9FB"
              item-text="name"
              item-value="uid"
              height="38px"
              :placeholder="$t('template')"
              append-icon="mdi-chevron-down"
              :menu-props="{ offsetY: true }"
              @change="handleTemplateChange"
            />
          </div>
          <div class="item-area ml-10 mb-1">
            <v-label
              class="text-left fs-14px text-theme-label font-weight-medium"
              style="width: 57%; display: flex; justify-content: start"
            >
              {{ $t("title") }} <strong class="red--text text--lighten-1">*</strong>
            </v-label>
            <v-label 
              v-if="isEditMode" 
              class="text-left fs-14px text-theme-label font-weight-medium"
            >
              {{ $t('id') }}
            </v-label>
          </div>
          <div class="item-area">
            <icon-dots />
            <v-text-field
              id="caseNameField"
              v-model="caseName"
              :placeholder="$t('title')"
              class="round-8 ml-5 w-60 field-theme"
              background-color="#F9F9FB"
              dense
              height="38px"
              :rules="titleValidation"
              required
            />
            <v-text-field
              v-if="isEditMode"
              v-model="caseID"
              placeholder="HM-1"
              background-color="#F9F9FB"
              class="round-8 pl-3 w-40 field-theme"
              dense
              height="38px"
              :disabled="true"
            />
          </div>
          <div
            v-if="!isExecution"
            class="item-area ml-10 mb-1"
          >
            <v-label class="text-left fs-14px text-theme-label font-weight-medium">
              {{ $t("folder") }}
            </v-label>
          </div>
          <div
            v-if="!isExecution"
            class="item-area"
          >
            <icon-dots />
            <v-select
              v-model="selectedFolderUID"
              :items="folders"
              item-text="name"
              item-value="uid"
              append-icon="mdi-chevron-down"
              class="ml-5 mt-0 pt-1 rounded-lg field-theme custom-prepend"
              height="38px"
              background-color="#F9F9FB"
              :menu-props="{ offsetY: true }"
            />
          </div>
          <div
            v-if="!isExecution"
            class="item-area ml-10 mb-1"
          >
            <v-label class="text-left fs-14px text-theme-label font-weight-medium">
              {{ $t("priority") }}
            </v-label>
          </div>
          <div
            v-if="!isExecution"
            class="item-area"
          >
            <icon-dots />
            <v-select
              v-model="casePriority"
              :items="priorities"
              item-text="name"
              append-icon="mdi-chevron-down"
              item-value="name"
              class="ml-5 mt-0 pt-1 rounded-lg field-theme custom-prepend"
              :placeholder="$t('choosePriority')"
              background-color="#F9F9FB"
              height="38px"
              :menu-props="{ offsetY: true }"
              return-object
            />
          </div>
          <div class="item-area ml-10 mb-1">
            <v-label class="text-left fs-14px text-theme-label font-weight-medium">
              {{ $t("tags") }}
            </v-label>
          </div>
          <div class="item-area">
            <icon-dots />
            <TagSelector
              v-model="caseTags"
              :items="tags"
              class="ml-5"
            />
          </div>
          <div
            v-if="!isExecution"
            class="item-area ml-10 mb-2"
          >
            <v-label class="text-left fs-14px text-theme-label font-weight-medium">
              {{ $t("addAttachment") }}
            </v-label>
          </div>
          <div
            v-if="!isExecution"
            class="item-area position-relative"
          >
            <icon-dots />
            <fileInput
              :files.sync="files"
              class="ml-5 w-full"
            />
          </div>

          <!-- custom fields from template -->
          <CustomFieldFromTemplate
            v-for="field in customFieldsFromTemplate"
            :key="field.id"
            :field="field"
            @delete="removeCustomField(field)"
          />
        </v-col>

        <!-- step -->
        <v-col
          v-if="steps.length"
          id="all_steps"
          :key="steps"
          cols="12"
          sm="12"
          class="pl-0 pr-0 block"
        >
          <step-editor
            v-for="(item, index) in steps"
            :key="item.id"
            :case-step="item"
            :title="`Step ` + (index + 1)"
            :step-index="index"
            :selected="selectedSteps.includes(index)"
            @delete-item="deleteStep"
            @duplicate-step="duplicateStep"
            @create-shared-step="handleCreateSharedStep"
            @description-change="changeDescription"
            @result-change="changeResult"
            @children-change="changeChildren"
            @toggle-selection="toggleStepSelection"
          />
        </v-col>

        <v-col
          cols="12"
          sm="12"
        >
          <div class="flex justify-center">
            <template>
              <div class="text-center">
                <v-menu
                  v-model="menu"
                  :close-on-content-click="false"
                  offset-y
                  max-width="205px"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="text-capitalize btn-theme mr-2"
                      depressed
                      v-bind="attrs"
                      height="38px"
                      min-width="205px"
                      v-on="on"
                    >
                      <v-icon size="16">
                        mdi-plus
                      </v-icon>
                      {{ $t("addSharedStep") }}
                    </v-btn>
                  </template>
                  <v-card>
                    <template>
                      <v-container>
                        <v-text-field
                          v-model="searchTerm"
                          class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0"
                          :placeholder="$t('placeHolder.searchByName')"
                          height="40"
                          background-color="#F9F9FB"
                          :hide-details="true"
                        >
                          <template v-slot:prepend-inner>
                            <SearchIcon />
                          </template>
                        </v-text-field>
                        <v-radio-group
                          v-if="isFilteredSelectedItemsHasData"
                          v-model="selectedItem"
                        >
                          <v-radio
                            v-for="(item, index) in filteredSelectedItems"
                            :key="index"
                            :label="item.name"
                            :value="item"
                          />
                        </v-radio-group>
                        <div
                          v-else
                          class="px-5 py-6 text-center"
                        >
                          {{ $t("noSharedStepsFound") }}, <br>{{ $t("click") }}
                          <span
                            class="cursor-pointer text-theme-primary"
                            @click="handleCreateSharedStep"
                          >{{ $t("here") }}</span>
                          {{ $t("toCreateOne") }}.
                        </div>
                        <v-spacer />
                        <div class="d-flex justify-center w-100 px-2">
                          <v-btn
                            class="text-capitalize btn-theme mr-2"
                            depressed
                            height="38px"
                            width="50%"
                            @click="menu = false"
                          >
                            {{ $t("cancel") }}
                          </v-btn>
                          <v-btn
                            class="text-capitalize btn-theme ml-2"
                            depressed
                            color="primary"
                            height="38px"
                            width="50%"
                            :class="{
                              'disabled-action': !isFilteredSelectedItemsHasData,
                            }"
                            @click="addSharedStep"
                          >
                            {{ $t("add") }}
                          </v-btn>
                        </div>
                      </v-container>
                    </template>
                  </v-card>
                </v-menu>
              </div>
            </template>
            <v-btn
              class="text-capitalize f-color-white btn-theme ml-2"
              color="primary"
              depressed
              height="38"
              min-width="205px"
              @click="addStep"
            >
              <v-icon size="16">
                mdi-plus
              </v-icon>
              {{ $t("addStep") }}
            </v-btn>
          </div>
        </v-col>

        <v-col
          cols="12"
          sm="12"
          class="flex justify-end pr-8"
        >
          <v-menu
            v-if="selectedSteps.length > 0"
            offset-y
            top
            class="rounded-lg"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                background-color="#F2F4F7"
                class="text-capitalize btn-theme"
                v-bind="attrs"
                height="38px"
                width="100%"
                max-width="128px"
                v-on="on"
              >
                {{ $t("actions") }}
                <v-icon size="16px">
                  mdi-chevron-up
                </v-icon>
              </v-btn>
            </template>
            <v-card
              rounded="8"
              elevation="0"
            >
              <v-list>
                <v-list-item
                  :key="1"
                  @click="duplicateSelectedSteps"
                >
                  <div class="d-flex align-center">
                    <duplicateIcon />
                  </div>
                  <v-list-item-title class="pl-3">
                    {{ $t("duplicate") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :key="2"
                  @click="handleCreateSharedStep"
                >
                  <div class="d-flex align-center">
                    <createSharedStepIcon />
                  </div>
                  <v-list-item-title class="pl-3">
                    {{ $t("createSharedStep") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :key="3"
                  @click="addChildStepToSelectedSteps"
                >
                  <div class="d-flex align-center">
                    <addChildStepIcon />
                  </div>
                  <v-list-item-title class="pl-3">
                    {{ $t("addChildStep") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :key="4"
                  @click="removeSelectedSteps"
                >
                  <div class="d-flex align-center">
                    <removeIcon />
                  </div>
                  <v-list-item-title class="color-red pl-3">
                    {{ $t("remove") }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-card>
          </v-menu>

          <v-menu
            v-else
            offset-y
            top
            class="rounded-lg"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                depressed
                background-color="#F2F4F7"
                class="text-capitalize btn-theme"
                v-bind="attrs"
                height="38px"
                width="100%"
                max-width="128px"
                v-on="on"
              >
                {{ $t("actions") }}
                <v-icon
                  size="16px"
                  class="ml-2"
                >
                  mdi-chevron-up
                </v-icon>
              </v-btn>
            </template>

            <v-card
              rounded="8"
              elevation="0"
            >
              <v-list>
                <v-list-item
                  v-if="!isExecution"
                  :key="1"
                  @click="saveAsTemplate"
                >
                  <v-list-item-title>
                    {{ $t("saveAsTemplate") }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :key="2"
                  @click="showCreateUpdateDialog = true"
                >
                  <v-list-item-title>
                    {{ $t("templatesPage.add_new_custom_field") }}
                  </v-list-item-title>
                </v-list-item>

                <v-menu
                  v-model="customFieldMenuRef"
                  class="custom-field-submenu"
                  :close-on-content-click="false"
                  max-width="300"
                  offset-x
                  left
                  top
                >
                  <template v-slot:activator="{ on: menu }">
                    <v-list-item
                      :key="3"
                      v-on="menu"
                    >
                      <v-list-item-title>
                        {{ $t("selectExistingCustomField") }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-card>
                    <v-container>
                      <v-text-field
                        v-model="searchCustomField"
                        class="text-field mt-0 rounded-lg field-theme custom-prepend pa-0"
                        :placeholder="$t('placeHolder.searchByName')"
                        height="40"
                        background-color="#F9F9FB"
                        :hide-details="true"
                      >
                        <template v-slot:prepend-inner>
                          <SearchIcon />
                        </template>
                      </v-text-field>
                      <v-item-group
                        v-if="filteredCustomFields.length"
                        multiple
                      >
                        <v-checkbox
                          v-for="field in filteredCustomFields"
                          :key="field.id"
                          v-model="selectedCustomFields"
                          :label="field.name"
                          :value="field"
                          :hide-details="true"
                          :disabled="isCustomFieldSelected(field)"
                          class="field-theme"
                          :ripple="false"
                          off-icon="icon-checkbox-off"
                          on-icon="icon-checkbox-on"
                        />
                      </v-item-group>
                      <div
                        v-else
                        class="px-5 py-6 text-center"
                      >
                        {{ $t("noCustomFieldsFound") }}
                      </div>
                      <v-spacer />
                      <div class="d-flex justify-center w-100 px-2 mt-5">
                        <v-btn
                          class="text-capitalize btn-theme mr-2"
                          depressed
                          height="38px"
                          width="50%"
                          @click="closeCustomFieldMenu"
                        >
                          {{ $t("cancel") }}
                        </v-btn>
                        <v-btn
                          class="text-capitalize btn-theme ml-2"
                          depressed
                          color="primary"
                          height="38px"
                          width="50%"
                          :class="{ 'disabled-action': !selectedCustomFields.length }"
                          @click="addCustomField"
                        >
                          {{ $t("add") }}
                        </v-btn>
                      </div>
                    </v-container>
                  </v-card>
                </v-menu>
              </v-list>
            </v-card>
          </v-menu>
          <v-btn
            depressed
            class="f-color-white ml-2 btn-theme text-capitalize"
            color="primary"
            width="100%"
            max-width="128px"
            :loading="createBtnLoading"
            :class="{ 'btn-loading-opacity': createBtnLoading }"
            @click="handleClickSave"
          >
            {{ $t("save") }}
          </v-btn>
        </v-col>

        <shared-step-dialog
          v-model="isOpenSharedStepDialog"
          :data="selectedSharedStep"
          :is-create-step-loading="isCreateStepLoading"
          @close-dialog="handleCloseSharedStepDialog"
          @create-shared-step="createSharedStep"
        />
        <CreateUpdateDialog
          v-model="showCreateUpdateDialog"
          @create-custom-field="createCustomField"
          @close-dialog="showCreateUpdateDialog = false"
        />
      </v-row>
    </div>
  </v-form>
</template>
<script>
import CustomFieldFromTemplate from "@/views/Tests/Case/Components/CustomFieldFromTemplate.vue";
import StepEditor from "@/views/Tests/Case/Components/StepEditor.vue";
import SharedStepDialog from "@/components/Admin/SharedStep/CreateUpdateDialog.vue";
import iconDots from "@/assets/svg/dots-20x20-gray.svg";
import { priorities as defaultPriorities } from "@/constants/grid.js";
import { showSuccessToast, showErrorToast } from "@/utils/toast";
import makeFoldersService from "@/services/api/folder";
import makeCasesService from "@/services/api/case";
import makeSharedStepService from "@/services/api/sharedStep";
import makeTemplateService from "@/services/api/template";
import SearchIcon from "@/assets/svg/search-icon.svg";
import removeIcon from "@/assets/svg/remove.svg";
import createSharedStepIcon from "@/assets/svg/create-shared-step.svg";
import addChildStepIcon from "@/assets/svg/add-child-step.svg";
import duplicateIcon from "@/assets/svg/duplicate.svg";
import { requiredAndMax255FieldValidationRules } from "@/utils/validation";
import makeTagService from "@/services/api/tag";
import { createNamespacedHelpers, mapActions } from "vuex";
import fileInput from "@/components/base/FileInput.vue";
import TagSelector from "@/components/base/TagSelector.vue";
import CreateUpdateDialog from "@/components/Admin/CustomField/CreateUpdateDialog.vue";
import makeCustomFieldService from "@/services/api/customField";
import { uuid } from "vue-uuid";
import colorPreferencesMixin from '@/mixins/colorPreferences';
import makeExecutionService from '@/services/api/execution'
import _ from 'lodash';

const { mapState, mapGetters } = createNamespacedHelpers("user");

let caseService;
let sharedStepService;
let templateService;

export default {
  components: {
    CustomFieldFromTemplate,
    StepEditor,
    SharedStepDialog,
    iconDots,
    SearchIcon,
    removeIcon,
    createSharedStepIcon,
    addChildStepIcon,
    duplicateIcon,
    fileInput,
    TagSelector,
    CreateUpdateDialog,
  },
  mixins: [colorPreferencesMixin],
  computed: {
    ...mapState(["currentAccount"]),
    filteredSelectedItems() {
      if (!this.searchTerm) {
        return this.selectedItems;
      }
      return this.selectedItems.filter((item) =>
        item.name.toLowerCase().includes(this.searchTerm.toLowerCase())
      );
    },
    isFilteredSelectedItemsHasData() {
      return this.filteredSelectedItems.length > 0;
    },
    stepsLength() {
      return this.steps.length;
    },
    filteredCustomFields() {
      if (!this.searchCustomField) {
        return this.customFields;
      }
      const searchTerm = this.searchCustomField.toLowerCase();
      return this.customFields.filter((field) =>
        field.name.toLowerCase().includes(searchTerm)
      );
    },
    isExecution(){
      return this.$route.query.isExecution && this.$route.query.id;
    },
  },
  data() {
    return {
      isEditMode: false,
      showCreateUpdateDialog: false,
      caseName: "",
      caseID: "",
      templateId: "",
      casePriority: "",
      caseTags: [],
      originalTags: [],
      folders: [],
      files: [],
      selectedFolderUID: "",
      editItem: {},
      isCreateStepLoading: false,
      isOpenSharedStepDialog: false,
      selectedSharedStep: {},
      menu: false,
      steps: [],
      stepCount: 0,
      customFieldsFromTemplate: [],
      selectedItems: [],
      selectedItem: null,
      searchTerm: "",
      templates: [],
      tags: [],
      selectedSteps: [],
      stepUniqueId: 0,
      isCreateTestCaseFormValid: false,
      titleValidation: requiredAndMax255FieldValidationRules(this),
      createBtnLoading: false,
      searchCustomField: "",
      customFieldMenuRef: false,
      customFields: [],
      selectedCustomFields: [],
      customFieldService: null,
      isLoading: false,
    };
  },
  async created() {
    caseService = makeCasesService(this.$api);
    templateService = makeTemplateService(this.$api);
    sharedStepService = makeSharedStepService(this.$api);

    this.getPriorities("testCase");
    this.getStatuses("testCase");

    const tagService = makeTagService(this.$api);
    try {
      const response = await tagService.getTags(this.$route.params.handle, 'cases');
      if (response.status === 200) {
        this.tags = response.data.map((tag) => {
          return { uid: tag.uid, name: tag.name };
        });
      } else {
        showErrorToast(this.$swal, "failedToFetchTags", { item: "Tags" });
      }
    } catch (error) {
      showErrorToast(this.$swal, "errorFetchingTags", { item: "Tags" });
    }

    if (this.$route.params.uid) {
      this.isEditMode = true;
      try {
        const response = await caseService.getCase(
          this.$route.params.handle,
          this.$route.params.key,
          this.$route.params.uid
        );

        if (response.status === 200) {
          this.editItem = response.data;
          this.selectedFolderUID = this.editItem.parentUid;
          this.templateId = this.editItem.testTemplateUid;

          // Initialize custom fields from template if they exist
          if (this.editItem.customFields && this.editItem.customFields.templateFields) {
            this.customFieldsFromTemplate = this.editItem.customFields.templateFields.map(
              (field) => ({
                ...field,
                id: field.uid,
                value: field.value || "",
              })
            );
          }

          this.stepUniqueId = this.getHighestStepId(this.editItem.steps);
          this.inputEditValues();
        } else {
          console.error("Error occurred in backend!");
        }
      } catch (error) {
        showErrorToast(this.$swal, "fetchError", { item: "Test case" });
      }
    } else {
      this.stepUniqueId = 0;
    }

    let customFields = {};

    if(this.$route.params.uid && this.$route.query.isExecution && this.$route.query.id){
      const executionService = makeExecutionService(this.$api);

      await executionService.getExecution(
        this.$route.params.handle,
        this.$route.params.key,
        this.$route.query.id
      ).then(response => {
        customFields = response.data?.customFields?.case;

        if(customFields){
          this.steps = customFields?.steps ?? [];
          this.stepUniqueId = this.getHighestStepId(this.steps);
          this.caseName = customFields?.name;
          this.caseTags = customFields?.tags?.map((tag) => {
            return { uid: tag.uid, name: tag.name };
          }) ?? [];
        }
      })
    }
    
  },
  async mounted() {
    await this.getProjectFolders();
    this.selectedFolderUID = parseInt(this.$route.query.folderUid) || "";
    this.fetchSharedSteps();

    const response = await templateService.getTemplates(
      this.$route.params.handle,
      this.$route.params.key,
      "per_page=9999&current_page=1"
    );
    this.templates = response.data.templates;
    this.getCustomFieldsFromTemplate(this.templateId);
    this.customFieldService = makeCustomFieldService(this.$api);
    this.fetchCustomFields();
  },
  methods: {
    ...mapActions({
      uploadToServer: "attachment/uploadToServer",
    }),
    async createCustomField(customField) {
      this.showCreateUpdateDialog = false;

      try {
        const response = await this.customFieldService.createCustomField(
          this.currentAccount.handle,
          this.$route.params.key,
          {
            ...customField,
            source: "Manual",
          }
        );
        showSuccessToast(this.$swal, "createSuccess", { item: "Custom field" });
        this.fetchCustomFields();
      } catch (err) {
        showErrorToast(this.$swal, "createError", { item: "Custom field" });
      }
    },
    updateFiles(files) {
      this.files = files;
    },
    handleTagsChange(selectedTagUids) {
      this.caseTags = selectedTagUids.map((tagUid) => {
        const tag = this.tags.find((t) => t.value === tagUid);
        return { uid: tag.value, name: tag.text };
      });
    },
    toggleSelection(item) {
      const index = this.caseTags.indexOf(item.text);
      if (index === -1) {
        this.caseTags.push(item.text);
      } else {
        this.caseTags.splice(index, 1);
      }
    },
    async getProjectFolders() {
      const folderService = makeFoldersService(this.$api);
      try {
        const {
          data: { folders },
        } = await folderService.getProjectFolders(
          this.$route.params.handle,
          this.$route.params.key
        );
        this.folders = this.findLeafNodes(folders);
      } catch (error) {
        console.error(error);
      }
    },
    findLeafNodes(folders) {
      let nodes = [];
      for (let idx = 0; idx < folders.length; idx++) {
        let parent = {
          name: folders[idx].name,
          uid: folders[idx].uid,
        };
        if (!folders[idx].children || folders[idx].children.length < 1) {
          nodes.push(parent);
        } else {
          nodes.push(parent, ...this.findLeafNodes(folders[idx].children));
        }
      }
      return nodes;
    },
    inputEditValues() {
      this.caseName = this.editItem.name;
      this.casePriority = this.editItem.customFields.priority;

      this.caseTags = this.editItem?.tags.map((tag) => {
        return { uid: tag.uid, name: tag.name };
      });
      this.originalTags = this.caseTags;
      this.caseID = `${this.$route.params.key} - ${this.editItem.uid}`;
      this.steps = this.editItem.steps;
      this.templateId = this.editItem.testTemplateUid;
    },
    handleCreateSharedStep() {
      this.selectedSharedStep = {
        uid: "",
        name: "",
        steps: [],
      };
      this.isOpenSharedStepDialog = true;
    },
    handleCloseSharedStepDialog() {
      this.isOpenSharedStepDialog = false;
    },

    async createSharedStep(sharedStep) {
      try {
        this.isCreateStepLoading = true;
        const response = await sharedStepService.createSharedStep(
          this.currentAccount.handle,
          this.$route.params.key,
          sharedStep
        );
        if (response.status === 200) {
          showSuccessToast(this.$swal, "createSuccess", { item: "Shared step" });
          this.isOpenSharedStepDialog = false;
          this.fetchSharedSteps();
        } else {
          showErrorToast(this.$swal, "createError", { item: "Shared step" });
        }
      } catch (err) {
        console.error("Error creating shared step:", err);
      } finally {
        this.isCreateStepLoading = false;
      }
    },
    async fetchSharedSteps() {
      try {
        const response = await sharedStepService.getSharedSteps(
          this.currentAccount.handle,
          this.$route.params.key
        );
        if (response.status === 200) {
          this.selectedItems = response.data.sharedSteps;
        }
      } catch (err) {
        console.error("Error fetching shared steps:", err);
      }
    },
    addStep() {
      this.stepUniqueId++;
      let step = {
        id: this.stepUniqueId,
        description: "",
        expectedResult: "",
        children: [],
        shared: false,
      };
      this.steps.push(step);
    },
    addSharedStep() {
      if (this.selectedItem !== null) {
        let selectedSharedSteps = this.selectedItem.steps.map((step) => ({
          ...step,
          uid: this.selectedItem.uid,
        }));

        selectedSharedSteps.forEach((step) => {
          this.stepUniqueId++;
          this.steps.push({
            ...step,
            id: this.stepUniqueId,
            sharedStepUid: step.uid,
          });
        });
        this.menu = false;
        this.searchTerm = "";
        this.selectedItem = null;
      }
    },
    deleteStep(index) {
      this.steps.splice(index, 1);
    },
    duplicateStep(index) {
      const stepToDuplicate = this.steps[index];
      const duplicatedStep = JSON.parse(JSON.stringify(stepToDuplicate));
      duplicatedStep.id = this.stepsLength + 1;
      // insert duplicated step after the current step
      this.steps.splice(index + 1, 0, duplicatedStep);
    },
    changeResult(result, stepId) {
      let step = this.getStepFromId(stepId);
      this.steps[step].expectedResult = result;
    },

    changeDescription(description, stepId) {
      let step = this.getStepFromId(stepId);
      this.steps[step].description = description;
    },

    changeChildren(children, stepIndex) {
      this.steps[stepIndex].children = children;
    },
    getStepFromId(stepId) {
      let steps = this.steps;
      for (let step in steps) {
        if (steps[step].id == stepId) {
          return step;
        }
      }
    },
    addChildStepToSelectedSteps() {
      this.selectedSteps.forEach((parentIndex) => {
        const parentStep = this.steps[parentIndex];

        if (!parentStep.children) {
          parentStep.children = [];
        }

        this.stepUniqueId++;
        const newChildStep = {
          id: this.stepUniqueId,
          description: "",
          expectedResult: "",
          children: [],
          shared: false,
          title: `Step ${parentIndex + 1}.${parentStep.children.length + 1}`,
        };

        parentStep.children.push(newChildStep);
      });

      this.selectedSteps = [];
    },
    toggleStepSelection(index, selected) {
      if (selected) {
        this.selectedSteps.push(index);
      } else {
        this.selectedSteps = this.selectedSteps.filter((i) => i !== index);
      }
    },

    removeSelectedSteps() {
      const sortedIndices = [...this.selectedSteps].sort((a, b) => b - a);

      sortedIndices.forEach((index) => {
        this.steps.splice(index, 1);
      });

      this.selectedSteps = [];
      this.renumberSteps();
    },
    renumberSteps() {
      this.steps.forEach((step, index) => {
        step.title = `Step ${index + 1}`;
        if (step.children) {
          step.children.forEach((childStep, childIndex) => {
            childStep.title = `Step ${index + 1}.${childIndex + 1}`;
          });
        }
      });
    },

    duplicateSelectedSteps() {
      this.selectedSteps.sort((a, b) => a - b);

      this.selectedSteps.forEach((index) => {
        const stepToDuplicate = this.steps[index];
        const duplicatedStep = JSON.parse(JSON.stringify(stepToDuplicate));
        duplicatedStep.id = this.stepsLength + 1;
        this.steps.push(duplicatedStep);
      });
      this.selectedSteps = [];
    },
    validateForm() {
      return this.$refs.form.validate();
    },
    resetForm() {
      return this.$refs.form.reset();
    },
    resetValidationForm() {
      return this.$refs.form.resetValidation();
    },
    handleClickSave() {
      if (this.validateForm()) {
        if (this.isEditMode) {
          this.saveTestCase();
        } else {
          this.createTestCase();
        }
      }
    },
    async createTestCase() {
      if (this.currentAccount.handle) {
        const handle = this.currentAccount.handle;

        const steps = this.steps.map((item, index) => ({
          ...item,
          title: item.shared ? item.title : `Step ${index + 1}`,
          children: item.children?.map((child, childIndex) => ({
            ...child,
            title: `Step ${index + 1}.${childIndex + 1}`,
          })),
        }));

        let data = {
          name: this.caseName,
          externalId: this.caseID,
          source: "testfiesta",
          projectKey: this.$route.params.key,
          parentId: this.selectedFolderUID,
          templateId: this.templateId,
          customFields: {
            priority: this.casePriority.name,
            templateFields: this.customFieldsFromTemplate,
						priorityColorId: this.casePriority.id,
          },
          tagIds: this.caseTags.map((tag) => tag.uid),
          steps,
        };

        try {
          const mediaType = "attachment";
          const relatedTo = "case";
          this.createBtnLoading = true;
          const response = await caseService.createTestCase(
            handle,
            this.$route.params.key,
            data
          );

          if (response.status == 200) {
            if (this.files.length)
              await Promise.all(
                this.files.map(async (file) => {
                  this.uploadToServer({
                    handle,
                    mediaType,
                    relatedTo,
                    relatedToUid: response.data.uid,
                    file,
                  });
                })
              );
            if (this.selectedFolderUID) {
              this.$router.push({
                name: "CasesFolder",
                params: {
                  folderUid: this.selectedFolderUID,
                },
              });
            } else {
              this.$router.push({ name: "Cases" });
            }
            this.getProjectFolders();
          } else {
            showErrorToast(this.$swal, "createError", { item: "Test case" });
          }
        } catch (err) {
          showErrorToast(this.$swal, "createError", { item: "Test case" });
        } finally {
          this.createBtnLoading = false;
        }
      }
    },
    async saveTestCase() {
      const handle = this.currentAccount.handle;
      const projectKey = this.$route.params.key;

      const steps = this.steps.map((item, index) => ({
        ...item,
        title: item.shared ? item.title : `Step ${index + 1}`,
        children: item.children?.map((child, childIndex) => ({
          ...child,
          title: `Step ${index + 1}.${childIndex + 1}`,
        })),
      }));

      const { tagIds, tagReplacements } = this.tagFomation(
        this.originalTags.map((tag) => tag.uid),
        this.caseTags.map((tag) => tag.uid)
      );
      if(this.$route.query?.isExecution && this.$route.query?.id){
        const executionId = this.$route.query.id;
        const payload = {
          name: this.caseName,
          tags: this.caseTags,
          steps,
        }
        const executionService = makeExecutionService(this.$api);

        await executionService.updateExecution(handle,projectKey,executionId,payload).then(() => {
          showSuccessToast(this.$swal, "updateSuccess", { item: "Test Case" });
        }).catch(() => {
          showSuccessToast(this.$swal, "updateError", { item: "Test Case" });
        })

        this.$router.push({ name: this.$route.query.redirectTo });
        return;
      }

      const payload = {
        name: this.caseName,
        externalId: this.caseID,
        projectKey: this.$route.params.key,
        templateId: this.templateId,
        parentId: this.selectedFolderUID,
        source: "testfiesta",
        customFields: {
          priority: this.casePriority,
          templateFields: this.customFieldsFromTemplate,
        },
        tagIds: tagIds,
        tagReplacements: tagReplacements,
        steps,
      };

      try {
        this.createBtnLoading = true;
        const response = await caseService.updateTestCase(
          handle,
          this.$route.params.key,
          this.editItem.testCaseRef,
          payload
        );
        if (response.status == 200) {
          if (this.$route.query.redirectTo) {
            if(this.$route.query.redirectTo === 'TestRunEdit'){
              this.$router.go(-1);
            }else{
              this.$router.push({ name: this.$route.query.redirectTo });
            }
          } else if (this.selectedFolderUID) {
            this.$router.push({
              name: "CasesFolder",
              params: {
                folderUid: this.selectedFolderUID,
              },
            });
          } else {
            this.$router.push({ name: "Cases" });
          }
        }
      } catch (err) {
        console.error("Error: ", err);
      } finally {
        this.createBtnLoading = false;
      }
    },

    tagFomation(originalTags, newTags) {
      const tagIdsToRemove = originalTags.filter((tagId) => !newTags.includes(tagId));
      const tagIdsToAdd = newTags.filter((tagId) => !originalTags.includes(tagId));

      const tagReplacements = [];

      if (tagIdsToRemove.length > 0) {
        tagReplacements.push({
          existingTagIds: tagIdsToRemove,
          newTagIds: [],
        });
      }

      if (tagIdsToAdd.length > 0) {
        tagReplacements.push({
          existingTagIds: [],
          newTagIds: tagIdsToAdd,
        });
      }

      return {
        tagIds: tagIdsToAdd,
        tagReplacements,
      };
    },
    getCustomFieldsFromTemplate(templateId) {
      const selectedTemplate = this.templates.find(
        (template) => template.uid === templateId
      );
      if (this.isEditMode && selectedTemplate) {
        if (this.editItem.testTemplateUid === templateId) {
          // Use existing template fields if template hasn't changed
          this.customFieldsFromTemplate = this.editItem.customFields.templateFields.map(
            (field) => ({
              ...field,
              id: field.uid || uuid.v4(), 
              value: field.value || "",
            })
          );
        } else {
          // Map new template fields if template has changed
          this.customFieldsFromTemplate = selectedTemplate.customFields.map((field) => ({
            ...field,
            id: field.uid || uuid.v4(), 
            value: field.value || "",
          }));
        }
      } else {
        // If we're not in edit mode
        if (!selectedTemplate) {
          return;
        } else {
          this.customFieldsFromTemplate = selectedTemplate.customFields.map((field) => ({
            ...field,
            id: field.uid || uuid.v4(), 
            value: field.value || "",
          }));
        }
      }
    },
    handleTemplateChange(value) {
      this.getCustomFieldsFromTemplate(value);
    },
    getHighestStepId(steps) {
      let highestId = 0;
      steps.forEach((step) => {
        if (step.id > highestId) {
          highestId = step.id;
        }
        if (step.children) {
          const childHighestId = this.getHighestStepId(step.children);
          if (childHighestId > highestId) {
            highestId = childHighestId;
          }
        }
      });
      return highestId;
    },
    selectCustomField(field) {
      // Handle the custom field selection here
      this.customFieldsFromTemplate.push(field);
    },
    closeCustomFieldMenu() {
      this.customFieldMenuRef = false;
      this.selectedCustomFields = []; // Reset array instead of single value
    },
    addCustomField() {
      if (this.selectedCustomFields.length) {
        this.customFieldsFromTemplate.push(...this.selectedCustomFields); // Push all selected fields
        this.closeCustomFieldMenu();
      }
    },
    async fetchCustomFields() {
      this.isLoading = true;
      try {
        const response = await this.customFieldService.getCustomFields(
          this.currentAccount.handle,
          this.$route.params.key
        );
        this.customFields = response.data.map((field) => ({
          ...field,
          id: field.uid,
        }));
      } catch (err) {
        this.redirectOnError(err.response?.status);
        showErrorToast(this.$swal, "fetchError", { item: "Custom fields" });
      } finally {
        this.isLoading = false;
      }
    },
    async saveAsTemplate() {
      if (!this.caseName) {
        showErrorToast(this.$swal, this.$t("templateNameRequired"));
        return;
      }

      try {
        const templatePayload = {
          name: this.caseName,
          uid: uuid.v4(),
          customFields: this.customFieldsFromTemplate.map((field) => ({
            name: field.name,
            dataType: field.type || field.dataType,
            options: field.options || [],
            defaultValue: field.value || "",
            id: uuid.v4(),
          })),
        };

        const response = await templateService.createTemplate(
          this.$route.params.handle,
          this.$route.params.key,
          templatePayload
        );

        if (response.status === 200) {
          showSuccessToast(this.$swal, "createSuccess", { item: "Template" });
          // Refresh templates list
          const templatesResponse = await templateService.getTemplates(
            this.$route.params.handle,
            this.$route.params.key,
            "per_page=9999&current_page=1"
          );
          this.templates = templatesResponse.data.templates;
        }
      } catch (error) {
        showErrorToast(this.$swal, "createError", { item: "Template" });
      }
    },
    isCustomFieldSelected(field){

      return this.customFieldsFromTemplate.some((f) => f.id === field.id);
    },
    removeCustomField(fieldToRemove) {
      this.customFieldsFromTemplate = this.customFieldsFromTemplate.filter(
        (field) => field.id !== fieldToRemove.id
      );
    },
  },
};
</script>

<style>
.justify-center {
  justify-content: center;
}

.property {
  min-width: 35px !important;
  background-color: white !important;
}

.f-color-white {
  color: white !important;
}

.f-color-red {
  color: #f2284e !important;
}

.justify-end {
  justify-content: end;
}

.align-start {
  text-align: start;
}

.block {
  display: block;
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.step-header {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}

.step-container {
  width: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
}

.position-relative {
  position: relative;
}

.trash-style {
  position: absolute !important;
  right: -35px;
  top: 13px;
  color: #f2284e !important;
}

.item-area {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;
}

.v-align-start {
  align-items: flex-start !important;
}

.back-btn-container {
  display: flex;
  align-items: flex-start;
}

.back-btn a {
  text-decoration: none;
  color: #0c2ff3 !important;
  font-weight: 600;
}

.back-btn a i {
  color: #0c2ff3 !important;
}

.case-contents {
  display: block;
  max-width: 438px;
  width: 438px;
}

.bg-color-grey {
  background-color: #f9f9fb;
}

.round-8 {
  border-radius: 8px;
}

.w-60 {
  width: 60%;
}

.w-40 {
  width: 40%;
}

.v-text-field__slot textarea {
  padding: 12px;
}

.font-inter {
  font-family: Inter;
}

.v-list-item:hover {
  background-color: #f9fafb;
  cursor: pointer;
}

.v-menu__content {
  text-align: left !important;
}

.color-red {
  color: #f2284e;
}
</style>
